// Test.js
import { getFunctions, httpsCallable } from 'firebase/functions';

const makePrevs = httpsCallable(getFunctions(), 'makePrevs');

const Test = ()=>{
    const handleClick = async()=>{
        console.log("send..");
        const res = await makePrevs({
            uid: "fYP0tTEipZOX8PPcYEBM7CB13nx2",
            mid: "1726105084957"
        })
        console.log(res.data);
    }
    return (
        <div>
           <button onClick={handleClick}>
                Test 
            </button>
        </div>
    )
}
export default Test;

// end
